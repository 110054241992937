<template>
<div  @click="musa"  id="content" class="site-content center-relative">
        <div id="alku" class="section no-page-title hideatmobile">
            <iframe
                src="https://iframe.videodelivery.net/caf825503f177196ec96c60f37cc6b03?muted=true&loop=true&autoplay=true&controls=false"
                style="border: none;" height="720" width="1280"
                allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
                allowfullscreen="true"></iframe>
        </div>
</div>
</template>

<script>
//import HelloWorld from "./components/HelloWorld.vue";

export default {
  name: "App",
  components: {
  //  HelloWorld,
  },
  data() {
    return {
      rokki: false
    }
  },
  methods: {
    musa() {
      this.rokki.play();
    }
  },
  mounted() {
    this.rokki = new Audio('/taustamusa.mp3');
  }
};
</script>

<style>

body {
  background-color: black;
  margin: 0px;
  padding: 0px;
}

    #alku {
        overflow: hidden;
        min-height: 100vh;
        display: flex;
        padding: 0;
        position: relative;
    }

    .container {
        height: 100%;
        overflow: hidden;
        padding: 0;
        position: relative;
    }

    #alku iframe {
        box-sizing: border-box;
        height: 56.25vw;
        left: 50%;
        min-height: 40%;
        min-width: 40%;
        transform: translate(-50%, -50%);
        position: absolute;
        top: 50%;
        width: 177.77777778vh;
    }

    #alku .section-wrapper {
        margin: auto;
        padding: 0;
    }

</style>
