import { createApp } from "vue";
import { createRouter } from "vue-router"
import { createWebHashHistory } from "vue-router"

import App from "./App.vue";
import Ukraine from "./Ukraine.vue";

const routes = [
    {
        path: '/',
        component: App
    },
    {
        path: '/oliver-stone-ukraine-on-fire',
        component: Ukraine
    }
]

const router = createRouter({
    history: createWebHashHistory(),
    routes,
})

const app = createApp({});
app.use(router)
app.mount("#app");
