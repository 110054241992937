<template>
<div  @click="musa"  id="content" class="site-content center-relative">
        <div id="alku" class="section no-page-title hideatmobile">
<div style="position: relative; padding-top: 42.62877442273535%;"><iframe src="https://iframe.videodelivery.net/f7ebdea4ecde5461c8ce7fc6015ca8e3?poster=https%3A%2F%2Fvideodelivery.net%2Ff7ebdea4ecde5461c8ce7fc6015ca8e3%2Fthumbnails%2Fthumbnail.jpg%3Ftime%3D%26height%3D600" style="border: none; position: absolute; top: 0; left: 0; height: 100%; width: 100%;" allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;" allowfullscreen="true"></iframe></div>
        </div>
</div>
</template>

<script>
//import HelloWorld from "./components/HelloWorld.vue";

export default {
  name: "Ukraine",
  components: {
  //  HelloWorld,
  },
  data() {
    return {
      rokki: false
    }
  }
};
</script>

<style>

body {
  background-color: white;
  margin: 0px;
  padding: 0px;
}

    #alku {
        overflow: hidden;
        min-height: 100vh;
        display: flex;
        padding: 0;
        position: relative;
    }

    .container {
        height: 100%;
        overflow: hidden;
        padding: 0;
        position: relative;
    }

    #alku iframe {
        box-sizing: border-box;
        height: 56.25vw;
        left: 50%;
        min-height: 40%;
        min-width: 40%;
        transform: translate(-50%, -50%);
        position: absolute;
        top: 50%;
        width: 177.77777778vh;
    }

    #alku .section-wrapper {
        margin: auto;
        padding: 0;
    }

</style>
